import React, { useState, useEffect } from 'react';
import { appWithTranslation, getCookie, Router, setCookie, useTranslation } from '@/util/helper';
import NProgress from 'nprogress';
import moment from 'moment';
import 'react-dates/initialize';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dates/lib/css/_datepicker.css';
import './global.css';
import SidebarContext from '@/context/SidebarContext';
import { withRedux, getOrInitializeStore } from '@/util/reduxUtils';
import { updateStore, preloadGlobalResources } from '@/util/authUtils';
import KYBNotificationContext from '@/context/KYBNotificationContext';
import ZendeskContext from '@/context/ZendeskContext';
import sentry from '@/util/sentry';
import Catch from '@/util/errorBoundary';
import * as smoothscroll from 'smoothscroll-polyfill';
import StoreDiscoveryContext from '@/context/StoreDiscoveryContext';
import { connect } from 'react-redux';
import { initializeGoogleAnalytics, registerPageView } from '@/util/googleAnalytics';
import { getSafeDeep } from '@/util/state';
import { SELECTED_COMPANY } from '@/util/constants';

import { selectCompany } from '@/redux/selectedCompany';

import authApi from '../api/auth/auth';
import { useRouter } from 'next/router';
import getConfig from 'next/config';

const { Sentry, captureException } = sentry();
const { publicRuntimeConfig } = getConfig();

Router.events.on('routeChangeStart', (url) => {
    console.log(`Loading: ${url}`);
    NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const MyApp = ({ Component, pageProps, hasError, errorEventId, companies }) => {
    const [chooseCompany, setChooseCompany] = useState(false);
    const router = useRouter();

    useEffect(() => {
        const onFocus = async () => {
            try {
                const res = await authApi().getUser();
                if (router.pathname == '/login') {
                    router.push('/');
                }
            } catch (e) {
                if (e.response.status == 403 || e.response.status == 401)
                    window.location.replace(`${publicRuntimeConfig.ellyManagerURL}/login`);
            }
        };
        window.addEventListener('focus', onFocus);
        return () => window.removeEventListener('focus', onFocus);
    }, []);

    useEffect(() => {
        // No need to detect this serverside
        setNotificationVisible(!(getCookie('kybSnooze') == 'true'));
        if (getCookie('storeDiscoverySnooze') == 'true')
            setStoreDiscoveryNotificationVisible(false);

        // Add sidebar open class for 769-1049px datepicker modal on payments
        document.body.classList.add('sidebarOpen');

        // TODO: bundle the polyfill into webpack config - this was a quick fix
        smoothscroll.polyfill();

        // Google anayltics tracking
        if (!window.GA_INITIALIZED) {
            initializeGoogleAnalytics();
            window.GA_INITIALIZED = true;
        }
        registerPageView();
    }, []);

    useEffect(() => {
        const localSelectedCompany = getCookie(SELECTED_COMPANY);
        const store = getOrInitializeStore();
        const state = store.getState();

        if (localSelectedCompany) {
            const company = JSON.parse(localSelectedCompany);
            store.dispatch(selectCompany(company));
            updateStore(store, company.id);
        }

        if (!localSelectedCompany && getSafeDeep(state, 'companies.length') > 1) {
            setChooseCompany(true);
        }

        if (!state.selectedCompany) {
            if (!localSelectedCompany) {
                // no previously selected company
                if (getSafeDeep(state, 'companies.length') > 1) {
                    setChooseCompany(true);
                }

                if (getSafeDeep(state, 'companies.length') == 1) {
                    const company = getSafeDeep(state, 'companies.0');
                    store.dispatch(selectCompany(company));
                    updateStore(store, company.id);
                }
            } else {
                // previously selected company
                if (getSafeDeep(!state, 'companies.length') > 1) {
                    setChooseCompany(true);
                }

                const company = JSON.parse(localSelectedCompany);
                store.dispatch(selectCompany(company));
                updateStore(store, company.id);
            }
        }
    }, [companies]);

    // Sidebar state and handlers for persisting in SSR navigation
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const toggleSidebar = () => {
        if (sidebarOpen) {
            document.body.classList.remove('sidebarOpen');
        } else {
            document.body.classList.add('sidebarOpen');
        }
        setSidebarOpen(!sidebarOpen);
    };

    // Notification state and handlers for persisting in SSR navigation
    const [notificationVisible, setNotificationVisible] = useState(false);
    const hideNotification = () => {
        setCookie('kybSnooze', 'true');
        setNotificationVisible(false);
    };

    // Store discovery notification state and handlers for persisting in SSR navigation
    const [storeDiscoveryNotificationVisible, setStoreDiscoveryNotificationVisible] = useState(
        false
    );
    const hideStoreDiscoveryNotification = () => {
        setCookie('storeDiscoverySnooze', 'true');
        setStoreDiscoveryNotificationVisible(false);
    };

    // Moment localization
    const { i18n } = useTranslation();
    moment.updateLocale(i18n.language, {
        week: {
            dow: 1,
            doy: 1
        }
    });

    /*    const PageHandler = Catch((props, error) => (
            (error.error || hasError) ? (
                <section>
                    <h1>There was an error!</h1>
                    <p>
                        <a
                            href="#"
                            onClick={() =>
                                Sentry.showReportDialog({ eventId: error.errorEventId || errorEventId })
                            }
                        >
                            📣 Report this error
                        </a>
                    </p>
                    <p>
                        <a
                            href="#"
                            onClick={() => {
                                window.location.reload(true)
                            }}
                        >
                            Or, try reloading the page
                        </a>
                    </p>
                </section>
            ) : (
                <SidebarContext.Provider value={{sidebarOpen, toggleSidebar}}>
                    <KYBNotificationContext.Provider value={{notificationVisible, hideNotification}}>
                        <div onClickCapture={e => {
                            e.stopPropagation()
                        }} id={"screenLoader"}>
                            <div className={'screen-pulse screen-loader-indicator'} />
                        </div>
                        <Component {...pageProps}/>

                    </KYBNotificationContext.Provider>
                </SidebarContext.Provider>
            )
        ), (error, errorInfo) => captureException(error, {errorInfo})) */

    return (
        <SidebarContext.Provider
            value={{ sidebarOpen, toggleSidebar, chooseCompany, setChooseCompany }}>
            <KYBNotificationContext.Provider value={{ notificationVisible, hideNotification }}>
                <StoreDiscoveryContext.Provider
                    value={{
                        notificationVisible: storeDiscoveryNotificationVisible,
                        setNotificationVisible: setStoreDiscoveryNotificationVisible,
                        hideNotification: hideStoreDiscoveryNotification
                    }}>
                    <div
                        onClickCapture={(e) => {
                            e.stopPropagation();
                        }}
                        id="screenLoader">
                        <div className="screen-pulse screen-loader-indicator" />
                    </div>
                    <Component {...pageProps} />
                </StoreDiscoveryContext.Provider>
            </KYBNotificationContext.Provider>
        </SidebarContext.Provider>
    );
};

MyApp.getInitialProps = async ({ Component, ctx }) => {
    try {
        let pageProps = {};

        if (Component && Component.getInitialProps) {
            pageProps = (await Component.getInitialProps(ctx)) || {};
        }
        if (!pageProps.namespacesRequired) {
            pageProps.namespacesRequired = ['common'];
            // eslint-disable-next-line no-console
            console.warn(`No translation namespace requested in page ${Component.name}`);
        }

        const store = ctx.reduxStore;
        let companies = [];

        if (store && store.getState) {
            const state = store.getState();
            companies = getSafeDeep(state, 'companies', []);
        }

        return { pageProps, companies };
    } catch (e) {
        const errorEventId = captureException(e, ctx);
        throw e;
    }
};

export default appWithTranslation(MyApp);
